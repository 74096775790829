import * as React from 'react'
import { graphql } from 'gatsby'
import './index.scss'
import Header from '../../components/header/header'
import WithHeaderLayour from '../../layouts/with-header'
import Footer from '../../components/footer/footer'
import Hero from '../../components/hero/hero'
import Quotation from '../../components/quotation/quotation'
import PageHeader from '../../components/page-header/page-header'
import SeloAbracam from '../../components/selo-abracam/selo-abracam'

const IndexPage = ({ data, location }) => {
  const nomeDaEmpresa = data.contentfulGeral.nomeDaEmpresa
  const telefone = data.contentfulGeral.telefone

  return (
    <WithHeaderLayour>
      <Header currentPath={location?.pathname} />
      <PageHeader
        headline={`Câmbio Comercial`}
        description={
          <>
            Câmbio Comercial são operações de envio e recebimento de recursos
            para liquidação de compromissos referentes a compra e venda de
            mercadorias entre países distintos (importação e exportação)
            <br />
            <br />
            Conte com a experiência da JMS para oferecer as soluções que sua
            empresa precisa para dar o passo adiante nos negócios
            internacionais.
          </>
        }
      />
      <div className="container" style={{ padding: '70px 0' }}>
        <h2>Importação</h2>
        <div>
          Para transações de até US$ 300 mil ou seu equivalente em outras moedas
          <ul>
            <li>Análise de documentos para fechamento</li>
            <li>Acompanhamento do mercado para conseguir as melhores taxas</li>
            <li>
              Acompanhamento da aplicação de DI e vinculação aos contratos de
              câmbio
            </li>
          </ul>
        </div>
        <h2>Exportação</h2>
        <div>
          Para transações de até US$ 300 mil ou seu equivalente em outras moedas
          <ul>
            <li>Análise de documentos para fechamento</li>
            <li>Acompanhamento do mercado para conseguir as melhores taxas</li>
          </ul>
        </div>
        <h2>Acompanhamento personalizado de ponta a ponta</h2>
        <div>
          Nossos especialistas vão ajudá-lo nas mais diversas questões para que
          as negociações sejam tranquilas, seguras e atinjam os resultados
          esperados. Veja como podemos colaborar:
        </div>
      </div>
      <SeloAbracam />
      <Footer />
    </WithHeaderLayour>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query CambioComercialQuery {
    contentfulGeral {
      nomeDaEmpresa
      telefone
    }
  }
`
